@charset "utf-8";
/* CSS Document */

@font-face{
	font-family:'farsi';
    src: url(IRANSansWeb.woff);

	src:url('IRANSansWeb.eot?v=4.7.0');
	src:url('IRANSansWeb.eot?#iefix&v=4.7.0') format('embedded-opentype'),url('IRANSansWeb.woff?v=4.7.0') format('woff'),url('IRANSansWeb.ttf?v=4.7.0') format('truetype');
	font-weight: normal;
    font-style: normal;
}
*{
	font-family: farsi !important;
}